/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Management Portal API
 * This is a management portal server
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  FormsDiscount,
  FormsDiscountBody,
  FormsOKType,
  FormsInvoiceForOpsList,
  ListAllInvoicesParams,
  FormsContractsResponse,
  ListAllContractParams,
  FormsContract,
  FormsContractInputBody,
  ApiHttpBody,
  FormsCostExploreTrendsOutput,
  FormsCostExploreTrendsInputBody,
  ExportCostExplorerTrendsParams,
  FormsCostExplorerArgs,
  FormsBudget,
  FormsSpendLimitThreshold,
  FormsCPPOBillingConfig,
  FormsCreditsPackage,
  FormsCreateCreditsPackageReq,
  FormsCreditsSummary,
  FormsInvoice,
  ListInvoicesV1Params,
  FormsCreateInvoiceReqBody,
  GenerateInitCRTableParams,
  FormsBillingConfig,
  FormsBillingInformation,
  FormsBillingInformationInput,
  FormsTaxRate,
  ListInvoicesParams,
  FormsSupportPlan,
  FormsBindedSupportPlan,
  FormsSupportPlanInput,
  FormsCommitmentInvoiceInput,
  FormsStripeInvoice,
  FormsPaymentMethod,
  FormsPaymentMethodType,
  FormsCostExplorerDetailsResult,
  FormsMonthlycostExplorerResult,
  FormsStripePaymentMethod,
  FormsUnitCosts,
  FormsUnitCostsInputBody
} from './interface'
import { customInstance } from '../../axios';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * List global discount
 * @summary List Global Discount
 */
export const listGlobalDiscount = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`, method: 'get'
    },
      options);
    }
  

export const getListGlobalDiscountQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`] as const;
  

    
export const getListGlobalDiscountQueryOptions = <TData = Awaited<ReturnType<typeof listGlobalDiscount>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListGlobalDiscountQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listGlobalDiscount>>> = () => listGlobalDiscount(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListGlobalDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof listGlobalDiscount>>>
export type ListGlobalDiscountQueryError = unknown

/**
 * @summary List Global Discount
 */
export const useListGlobalDiscount = <TData = Awaited<ReturnType<typeof listGlobalDiscount>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListGlobalDiscountQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create global discount
 * @summary Create Global Discount
 */
export const createGlobalDiscount = (
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getCreateGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGlobalDiscount>>, TError,{data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createGlobalDiscount>>, TError,{data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGlobalDiscount>>, {data: FormsDiscountBody}> = (props) => {
          const {data} = props ?? {};

          return  createGlobalDiscount(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof createGlobalDiscount>>>
    export type CreateGlobalDiscountMutationBody = FormsDiscountBody
    export type CreateGlobalDiscountMutationError = unknown

    /**
 * @summary Create Global Discount
 */
export const useCreateGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGlobalDiscount>>, TError,{data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update global discount
 * @summary Update Global Discount
 */
export const updateGlobalDiscount = (
    discountId: string,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts/${discountId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getUpdateGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGlobalDiscount>>, {discountId: string;data: FormsDiscountBody}> = (props) => {
          const {discountId,data} = props ?? {};

          return  updateGlobalDiscount(discountId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateGlobalDiscount>>>
    export type UpdateGlobalDiscountMutationBody = FormsDiscountBody
    export type UpdateGlobalDiscountMutationError = unknown

    /**
 * @summary Update Global Discount
 */
export const useUpdateGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Reclaim global discount
 * @summary Reclaim Global Discount
 */
export const reclaimGlobalDiscount = (
    discountId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts/${discountId}`, method: 'delete'
    },
      options);
    }
  


export const getReclaimGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, {discountId: string}> = (props) => {
          const {discountId} = props ?? {};

          return  reclaimGlobalDiscount(discountId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReclaimGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof reclaimGlobalDiscount>>>
    
    export type ReclaimGlobalDiscountMutationError = unknown

    /**
 * @summary Reclaim Global Discount
 */
export const useReclaimGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReclaimGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const listAllInvoices = (
    params?: ListAllInvoicesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoiceForOpsList[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/invoices`, method: 'get',
        params
    },
      options);
    }
  

export const getListAllInvoicesQueryKey = (params?: ListAllInvoicesParams,) => [`/api/v1/devops/billing-proxy/api/v1/billing/invoices`, ...(params ? [params]: [])] as const;
  

    
export const getListAllInvoicesQueryOptions = <TData = Awaited<ReturnType<typeof listAllInvoices>>, TError = unknown>(params?: ListAllInvoicesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listAllInvoices>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllInvoicesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllInvoices>>> = () => listAllInvoices(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListAllInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listAllInvoices>>>
export type ListAllInvoicesQueryError = unknown

export const useListAllInvoices = <TData = Awaited<ReturnType<typeof listAllInvoices>>, TError = unknown>(
 params?: ListAllInvoicesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllInvoicesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List All Contract
 */
export const listAllContract = (
    tenantId: string,
    billingConfigId: number,
    params?: ListAllContractParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsContractsResponse>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts`, method: 'get',
        params
    },
      options);
    }
  

export const getListAllContractQueryKey = (tenantId: string,
    billingConfigId: number,
    params?: ListAllContractParams,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts`, ...(params ? [params]: [])] as const;
  

    
export const getListAllContractQueryOptions = <TData = Awaited<ReturnType<typeof listAllContract>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    params?: ListAllContractParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listAllContract>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllContractQueryKey(tenantId,billingConfigId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllContract>>> = () => listAllContract(tenantId,billingConfigId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListAllContractQueryResult = NonNullable<Awaited<ReturnType<typeof listAllContract>>>
export type ListAllContractQueryError = unknown

/**
 * @summary List All Contract
 */
export const useListAllContract = <TData = Awaited<ReturnType<typeof listAllContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    params?: ListAllContractParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllContractQueryOptions(tenantId,billingConfigId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Contract
 */
export const createContract = (
    tenantId: string,
    billingConfigId: number,
    formsContractInputBody: FormsContractInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsContract>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsContractInputBody
    },
      options);
    }
  


export const getCreateContractMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContract>>, TError,{tenantId: string;billingConfigId: number;data: FormsContractInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createContract>>, TError,{tenantId: string;billingConfigId: number;data: FormsContractInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContract>>, {tenantId: string;billingConfigId: number;data: FormsContractInputBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  createContract(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateContractMutationResult = NonNullable<Awaited<ReturnType<typeof createContract>>>
    export type CreateContractMutationBody = FormsContractInputBody
    export type CreateContractMutationError = unknown

    /**
 * @summary Create Contract
 */
export const useCreateContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContract>>, TError,{tenantId: string;billingConfigId: number;data: FormsContractInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateContractMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Contract
 */
export const getContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsContract>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}`, method: 'get'
    },
      options);
    }
  

export const getGetContractQueryKey = (tenantId: string,
    billingConfigId: number,
    contractId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}`] as const;
  

    
export const getGetContractQueryOptions = <TData = Awaited<ReturnType<typeof getContract>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getContract>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetContractQueryKey(tenantId,billingConfigId,contractId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContract>>> = () => getContract(tenantId,billingConfigId,contractId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && contractId), ...queryOptions}}

export type GetContractQueryResult = NonNullable<Awaited<ReturnType<typeof getContract>>>
export type GetContractQueryError = unknown

/**
 * @summary Get Contract
 */
export const useGetContract = <TData = Awaited<ReturnType<typeof getContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContractQueryOptions(tenantId,billingConfigId,contractId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Contract
 */
export const updateContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
    formsContractInputBody: FormsContractInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsContract>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsContractInputBody
    },
      options);
    }
  


export const getUpdateContractMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;data: FormsContractInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;data: FormsContractInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContract>>, {tenantId: string;billingConfigId: number;contractId: string;data: FormsContractInputBody}> = (props) => {
          const {tenantId,billingConfigId,contractId,data} = props ?? {};

          return  updateContract(tenantId,billingConfigId,contractId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateContractMutationResult = NonNullable<Awaited<ReturnType<typeof updateContract>>>
    export type UpdateContractMutationBody = FormsContractInputBody
    export type UpdateContractMutationError = unknown

    /**
 * @summary Update Contract
 */
export const useUpdateContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;data: FormsContractInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateContractMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Operate Contract
 */
export const operateContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
    action: 'create' | 'submit' | 'cancel' | 'delete' | 'terminate',
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}/actions/${action}`, method: 'post'
    },
      options);
    }
  


export const getOperateContractMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;action: 'create' | 'submit' | 'cancel' | 'delete' | 'terminate'}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof operateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;action: 'create' | 'submit' | 'cancel' | 'delete' | 'terminate'}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operateContract>>, {tenantId: string;billingConfigId: number;contractId: string;action: 'create' | 'submit' | 'cancel' | 'delete' | 'terminate'}> = (props) => {
          const {tenantId,billingConfigId,contractId,action} = props ?? {};

          return  operateContract(tenantId,billingConfigId,contractId,action,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OperateContractMutationResult = NonNullable<Awaited<ReturnType<typeof operateContract>>>
    
    export type OperateContractMutationError = unknown

    /**
 * @summary Operate Contract
 */
export const useOperateContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operateContract>>, TError,{tenantId: string;billingConfigId: number;contractId: string;action: 'create' | 'submit' | 'cancel' | 'delete' | 'terminate'}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getOperateContractMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Download Contract
 */
export const downloadContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}/download`, method: 'get'
    },
      options);
    }
  

export const getDownloadContractQueryKey = (tenantId: string,
    billingConfigId: number,
    contractId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}/download`] as const;
  

    
export const getDownloadContractQueryOptions = <TData = Awaited<ReturnType<typeof downloadContract>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof downloadContract>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadContractQueryKey(tenantId,billingConfigId,contractId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContract>>> = () => downloadContract(tenantId,billingConfigId,contractId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && contractId), ...queryOptions}}

export type DownloadContractQueryResult = NonNullable<Awaited<ReturnType<typeof downloadContract>>>
export type DownloadContractQueryError = unknown

/**
 * @summary Download Contract
 */
export const useDownloadContract = <TData = Awaited<ReturnType<typeof downloadContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadContractQueryOptions(tenantId,billingConfigId,contractId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cost Explorer Trends
 */
export const getCostExplorerTrends = (
    tenantId: string,
    billingConfigId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExploreTrendsOutput>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/trends`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      options);
    }
  


export const getGetCostExplorerTrendsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCostExplorerTrends>>, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  getCostExplorerTrends(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GetCostExplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof getCostExplorerTrends>>>
    export type GetCostExplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type GetCostExplorerTrendsMutationError = unknown

    /**
 * @summary Cost Explorer Trends
 */
export const useGetCostExplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGetCostExplorerTrendsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const exportCostExplorerTrends = (
    tenantId: string,
    billingConfigId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
    params?: ExportCostExplorerTrendsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<number[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/trends/csv`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody,
        params
    },
      options);
    }
  


export const getExportCostExplorerTrendsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody;params?: ExportCostExplorerTrendsParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof exportCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody;params?: ExportCostExplorerTrendsParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportCostExplorerTrends>>, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody;params?: ExportCostExplorerTrendsParams}> = (props) => {
          const {tenantId,billingConfigId,data,params} = props ?? {};

          return  exportCostExplorerTrends(tenantId,billingConfigId,data,params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ExportCostExplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof exportCostExplorerTrends>>>
    export type ExportCostExplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type ExportCostExplorerTrendsMutationError = unknown

    export const useExportCostExplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody;params?: ExportCostExplorerTrendsParams}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getExportCostExplorerTrendsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Explorer Args
 */
export const getTenantCostExplorerArgs = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExplorerArgs>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/args`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCostExplorerArgsQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/args`] as const;
  

    
export const getGetTenantCostExplorerArgsQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCostExplorerArgsQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>> = () => getTenantCostExplorerArgs(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCostExplorerArgsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>>
export type GetTenantCostExplorerArgsQueryError = unknown

/**
 * @summary Cost Explorer Args
 */
export const useGetTenantCostExplorerArgs = <TData = Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCostExplorerArgsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cost Explorer Trends
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends = (
    tenantId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExploreTrendsOutput>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/trends`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends>>, {tenantId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsMutationError = unknown

    /**
 * @summary Cost Explorer Trends
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrends>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv = (
    tenantId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<number[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/trends/csv`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, {tenantId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationBody = FormsCostExploreTrendsInputBody
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationError = unknown

    export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List All Tenant Budgets
 */
export const listAllBudgets = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBudget[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets`, method: 'get'
    },
      options);
    }
  

export const getListAllBudgetsQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets`] as const;
  

    
export const getListAllBudgetsQueryOptions = <TData = Awaited<ReturnType<typeof listAllBudgets>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllBudgets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listAllBudgets>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllBudgetsQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllBudgets>>> = () => listAllBudgets(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type ListAllBudgetsQueryResult = NonNullable<Awaited<ReturnType<typeof listAllBudgets>>>
export type ListAllBudgetsQueryError = unknown

/**
 * @summary List All Tenant Budgets
 */
export const useListAllBudgets = <TData = Awaited<ReturnType<typeof listAllBudgets>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllBudgets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllBudgetsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Budget
 */
export const createBudget = (
    tenantId: string,
    formsBudget: FormsBudget,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBudget[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsBudget
    },
      options);
    }
  


export const getCreateBudgetMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBudget>>, TError,{tenantId: string;data: FormsBudget}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createBudget>>, TError,{tenantId: string;data: FormsBudget}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBudget>>, {tenantId: string;data: FormsBudget}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  createBudget(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateBudgetMutationResult = NonNullable<Awaited<ReturnType<typeof createBudget>>>
    export type CreateBudgetMutationBody = FormsBudget
    export type CreateBudgetMutationError = unknown

    /**
 * @summary Create Budget
 */
export const useCreateBudget = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBudget>>, TError,{tenantId: string;data: FormsBudget}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateBudgetMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Budget
 */
export const getBudget = (
    tenantId: string,
    budgetId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBudget>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}`, method: 'get'
    },
      options);
    }
  

export const getGetBudgetQueryKey = (tenantId: string,
    budgetId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}`] as const;
  

    
export const getGetBudgetQueryOptions = <TData = Awaited<ReturnType<typeof getBudget>>, TError = unknown>(tenantId: string,
    budgetId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBudget>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBudget>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBudgetQueryKey(tenantId,budgetId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBudget>>> = () => getBudget(tenantId,budgetId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && budgetId), ...queryOptions}}

export type GetBudgetQueryResult = NonNullable<Awaited<ReturnType<typeof getBudget>>>
export type GetBudgetQueryError = unknown

/**
 * @summary Get Budget
 */
export const useGetBudget = <TData = Awaited<ReturnType<typeof getBudget>>, TError = unknown>(
 tenantId: string,
    budgetId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBudget>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBudgetQueryOptions(tenantId,budgetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Budget
 */
export const deleteBudget = (
    tenantId: string,
    budgetId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteBudgetMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBudget>>, TError,{tenantId: string;budgetId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteBudget>>, TError,{tenantId: string;budgetId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBudget>>, {tenantId: string;budgetId: string}> = (props) => {
          const {tenantId,budgetId} = props ?? {};

          return  deleteBudget(tenantId,budgetId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteBudgetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBudget>>>
    
    export type DeleteBudgetMutationError = unknown

    /**
 * @summary Delete Budget
 */
export const useDeleteBudget = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBudget>>, TError,{tenantId: string;budgetId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteBudgetMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Budget Limitation
 */
export const updateBudget = (
    tenantId: string,
    budgetId: string,
    formsBudget: FormsBudget,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBudget[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsBudget
    },
      options);
    }
  


export const getUpdateBudgetMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBudget>>, TError,{tenantId: string;budgetId: string;data: FormsBudget}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateBudget>>, TError,{tenantId: string;budgetId: string;data: FormsBudget}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBudget>>, {tenantId: string;budgetId: string;data: FormsBudget}> = (props) => {
          const {tenantId,budgetId,data} = props ?? {};

          return  updateBudget(tenantId,budgetId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateBudgetMutationResult = NonNullable<Awaited<ReturnType<typeof updateBudget>>>
    export type UpdateBudgetMutationBody = FormsBudget
    export type UpdateBudgetMutationError = unknown

    /**
 * @summary Update Budget Limitation
 */
export const useUpdateBudget = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBudget>>, TError,{tenantId: string;budgetId: string;data: FormsBudget}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateBudgetMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Budget Threshold
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds = (
    tenantId: string,
    budgetId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSpendLimitThreshold[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}/thresholds`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds>>, TError,{tenantId: string;budgetId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds>>, TError,{tenantId: string;budgetId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds>>, {tenantId: string;budgetId: string}> = (props) => {
          const {tenantId,budgetId} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds(tenantId,budgetId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds>>>
    
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsMutationError = unknown

    /**
 * @summary Add Budget Threshold
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholds>>, TError,{tenantId: string;budgetId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Budget Threshold
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId = (
    tenantId: string,
    budgetId: string,
    thresholdId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSpendLimitThreshold[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}/thresholds/${thresholdId}`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, {tenantId: string;budgetId: string;thresholdId: string}> = (props) => {
          const {tenantId,budgetId,thresholdId} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId(tenantId,budgetId,thresholdId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>>
    
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationError = unknown

    /**
 * @summary Update Budget Threshold
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Budget Threshold
 */
export const deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId = (
    tenantId: string,
    budgetId: string,
    thresholdId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/budgets/${budgetId}/thresholds/${thresholdId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, {tenantId: string;budgetId: string;thresholdId: string}> = (props) => {
          const {tenantId,budgetId,thresholdId} = props ?? {};

          return  deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId(tenantId,budgetId,thresholdId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>>
    
    export type DeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationError = unknown

    /**
 * @summary Delete Budget Threshold
 */
export const useDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdId>>, TError,{tenantId: string;budgetId: string;thresholdId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBudgetsBudgetIdThresholdsThresholdIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create CPPO Billing Config
 */
export const createCPPOBillingConfig = (
    tenantId: string,
    formsCPPOBillingConfig: FormsCPPOBillingConfig,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/cppo_billing_config`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCPPOBillingConfig
    },
      options);
    }
  


export const getCreateCPPOBillingConfigMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCPPOBillingConfig>>, TError,{tenantId: string;data: FormsCPPOBillingConfig}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCPPOBillingConfig>>, TError,{tenantId: string;data: FormsCPPOBillingConfig}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCPPOBillingConfig>>, {tenantId: string;data: FormsCPPOBillingConfig}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  createCPPOBillingConfig(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCPPOBillingConfigMutationResult = NonNullable<Awaited<ReturnType<typeof createCPPOBillingConfig>>>
    export type CreateCPPOBillingConfigMutationBody = FormsCPPOBillingConfig
    export type CreateCPPOBillingConfigMutationError = unknown

    /**
 * @summary Create CPPO Billing Config
 */
export const useCreateCPPOBillingConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCPPOBillingConfig>>, TError,{tenantId: string;data: FormsCPPOBillingConfig}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCPPOBillingConfigMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Tenants Credits
 */
export const listTenantsCredits = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditsPackage[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits`, method: 'get'
    },
      options);
    }
  

export const getListTenantsCreditsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits`] as const;
  

    
export const getListTenantsCreditsQueryOptions = <TData = Awaited<ReturnType<typeof listTenantsCredits>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsCreditsQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantsCredits>>> = () => listTenantsCredits(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListTenantsCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantsCredits>>>
export type ListTenantsCreditsQueryError = unknown

/**
 * @summary List Tenants Credits
 */
export const useListTenantsCredits = <TData = Awaited<ReturnType<typeof listTenantsCredits>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantsCreditsQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Credits
 */
export const createCredits = (
    tenantId: string,
    billingConfigId: number,
    formsCreateCreditsPackageReq: FormsCreateCreditsPackageReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditsPackage>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateCreditsPackageReq
    },
      options);
    }
  


export const getCreateCreditsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCredits>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateCreditsPackageReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCredits>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateCreditsPackageReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCredits>>, {tenantId: string;billingConfigId: number;data: FormsCreateCreditsPackageReq}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  createCredits(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCreditsMutationResult = NonNullable<Awaited<ReturnType<typeof createCredits>>>
    export type CreateCreditsMutationBody = FormsCreateCreditsPackageReq
    export type CreateCreditsMutationError = unknown

    /**
 * @summary Create Credits
 */
export const useCreateCredits = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCredits>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateCreditsPackageReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCreditsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Credits
 */
export const getTenantCredits = (
    tenantId: string,
    billingConfigId: number,
    creditId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditsPackage>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits/${creditId}`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCreditsQueryKey = (tenantId: string,
    billingConfigId: number,
    creditId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits/${creditId}`] as const;
  

    
export const getGetTenantCreditsQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCredits>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    creditId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCredits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCreditsQueryKey(tenantId,billingConfigId,creditId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCredits>>> = () => getTenantCredits(tenantId,billingConfigId,creditId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && creditId), ...queryOptions}}

export type GetTenantCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCredits>>>
export type GetTenantCreditsQueryError = unknown

/**
 * @summary Get Credits
 */
export const useGetTenantCredits = <TData = Awaited<ReturnType<typeof getTenantCredits>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    creditId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCreditsQueryOptions(tenantId,billingConfigId,creditId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Reclaim Credits
 */
export const reclaimCredits = (
    tenantId: string,
    billingConfigId: number,
    creditId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits/${creditId}/actions/reclaim`, method: 'post'
    },
      options);
    }
  


export const getReclaimCreditsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimCredits>>, TError,{tenantId: string;billingConfigId: number;creditId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reclaimCredits>>, TError,{tenantId: string;billingConfigId: number;creditId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reclaimCredits>>, {tenantId: string;billingConfigId: number;creditId: string}> = (props) => {
          const {tenantId,billingConfigId,creditId} = props ?? {};

          return  reclaimCredits(tenantId,billingConfigId,creditId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReclaimCreditsMutationResult = NonNullable<Awaited<ReturnType<typeof reclaimCredits>>>
    
    export type ReclaimCreditsMutationError = unknown

    /**
 * @summary Reclaim Credits
 */
export const useReclaimCredits = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimCredits>>, TError,{tenantId: string;billingConfigId: number;creditId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReclaimCreditsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenants Credits Summary
 */
export const getTenantsCreditsSummary = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditsSummary>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits_summary`, method: 'get'
    },
      options);
    }
  

export const getGetTenantsCreditsSummaryQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits_summary`] as const;
  

    
export const getGetTenantsCreditsSummaryQueryOptions = <TData = Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantsCreditsSummaryQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantsCreditsSummary>>> = () => getTenantsCreditsSummary(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type GetTenantsCreditsSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsCreditsSummary>>>
export type GetTenantsCreditsSummaryQueryError = unknown

/**
 * @summary Get Tenants Credits Summary
 */
export const useGetTenantsCreditsSummary = <TData = Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantsCreditsSummaryQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * List tenant discount
 * @summary List Tenant Discount
 */
export const listTenantDiscount = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/discounts`, method: 'get'
    },
      options);
    }
  

export const getListTenantDiscountQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/discounts`] as const;
  

    
export const getListTenantDiscountQueryOptions = <TData = Awaited<ReturnType<typeof listTenantDiscount>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantDiscountQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantDiscount>>> = () => listTenantDiscount(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListTenantDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantDiscount>>>
export type ListTenantDiscountQueryError = unknown

/**
 * @summary List Tenant Discount
 */
export const useListTenantDiscount = <TData = Awaited<ReturnType<typeof listTenantDiscount>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantDiscountQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create tenant discount
 * @summary Create Tenant Discount
 */
export const createTenantDiscount = (
    tenantId: string,
    billingConfigId: number,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/discounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getCreateTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTenantDiscount>>, {tenantId: string;billingConfigId: number;data: FormsDiscountBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  createTenantDiscount(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof createTenantDiscount>>>
    export type CreateTenantDiscountMutationBody = FormsDiscountBody
    export type CreateTenantDiscountMutationError = unknown

    /**
 * @summary Create Tenant Discount
 */
export const useCreateTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update tenant discount
 * @summary Update Tenant Discount
 */
export const putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId = (
    tenantId: string,
    billingConfigId: number,
    discountId: string,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/discounts/${discountId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId>>, TError,{tenantId: string;billingConfigId: number;discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId>>, TError,{tenantId: string;billingConfigId: number;discountId: string;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId>>, {tenantId: string;billingConfigId: number;discountId: string;data: FormsDiscountBody}> = (props) => {
          const {tenantId,billingConfigId,discountId,data} = props ?? {};

          return  putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId(tenantId,billingConfigId,discountId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId>>>
    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountIdMutationBody = FormsDiscountBody
    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountIdMutationError = unknown

    /**
 * @summary Update Tenant Discount
 */
export const usePutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountId>>, TError,{tenantId: string;billingConfigId: number;discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdDiscountsDiscountIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Reclaim tenant discount
 * @summary Reclaim Tenant Discount
 */
export const reclaimTenantDiscount = (
    tenantId: string,
    billingConfigId: number,
    discountId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/discounts/${discountId}`, method: 'delete'
    },
      options);
    }
  


export const getReclaimTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;discountId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reclaimTenantDiscount>>, {tenantId: string;billingConfigId: number;discountId: string}> = (props) => {
          const {tenantId,billingConfigId,discountId} = props ?? {};

          return  reclaimTenantDiscount(tenantId,billingConfigId,discountId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReclaimTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof reclaimTenantDiscount>>>
    
    export type ReclaimTenantDiscountMutationError = unknown

    /**
 * @summary Reclaim Tenant Discount
 */
export const useReclaimTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;billingConfigId: number;discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReclaimTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Invoices
 */
export const listInvoicesV1 = (
    tenantId: string,
    params?: ListInvoicesV1Params,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices`, method: 'get',
        params
    },
      options);
    }
  

export const getListInvoicesV1QueryKey = (tenantId: string,
    params?: ListInvoicesV1Params,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices`, ...(params ? [params]: [])] as const;
  

    
export const getListInvoicesV1QueryOptions = <TData = Awaited<ReturnType<typeof listInvoicesV1>>, TError = unknown>(tenantId: string,
    params?: ListInvoicesV1Params, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInvoicesV1>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listInvoicesV1>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInvoicesV1QueryKey(tenantId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInvoicesV1>>> = () => listInvoicesV1(tenantId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type ListInvoicesV1QueryResult = NonNullable<Awaited<ReturnType<typeof listInvoicesV1>>>
export type ListInvoicesV1QueryError = unknown

/**
 * @summary List Invoices
 */
export const useListInvoicesV1 = <TData = Awaited<ReturnType<typeof listInvoicesV1>>, TError = unknown>(
 tenantId: string,
    params?: ListInvoicesV1Params, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInvoicesV1>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInvoicesV1QueryOptions(tenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Invoice
 */
export const createInvoiceV1 = (
    tenantId: string,
    formsCreateInvoiceReqBody: FormsCreateInvoiceReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateInvoiceReqBody
    },
      options);
    }
  


export const getCreateInvoiceV1MutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvoiceV1>>, TError,{tenantId: string;data: FormsCreateInvoiceReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createInvoiceV1>>, TError,{tenantId: string;data: FormsCreateInvoiceReqBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInvoiceV1>>, {tenantId: string;data: FormsCreateInvoiceReqBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  createInvoiceV1(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateInvoiceV1MutationResult = NonNullable<Awaited<ReturnType<typeof createInvoiceV1>>>
    export type CreateInvoiceV1MutationBody = FormsCreateInvoiceReqBody
    export type CreateInvoiceV1MutationError = unknown

    /**
 * @summary Create Invoice
 */
export const useCreateInvoiceV1 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvoiceV1>>, TError,{tenantId: string;data: FormsCreateInvoiceReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateInvoiceV1MutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Invoice
 */
export const getInvoiceV1 = (
    tenantId: string,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices/${invoiceId}`, method: 'get'
    },
      options);
    }
  

export const getGetInvoiceV1QueryKey = (tenantId: string,
    invoiceId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices/${invoiceId}`] as const;
  

    
export const getGetInvoiceV1QueryOptions = <TData = Awaited<ReturnType<typeof getInvoiceV1>>, TError = unknown>(tenantId: string,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceV1>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getInvoiceV1>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoiceV1QueryKey(tenantId,invoiceId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceV1>>> = () => getInvoiceV1(tenantId,invoiceId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && invoiceId), ...queryOptions}}

export type GetInvoiceV1QueryResult = NonNullable<Awaited<ReturnType<typeof getInvoiceV1>>>
export type GetInvoiceV1QueryError = unknown

/**
 * @summary Get Invoice
 */
export const useGetInvoiceV1 = <TData = Awaited<ReturnType<typeof getInvoiceV1>>, TError = unknown>(
 tenantId: string,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoiceV1>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoiceV1QueryOptions(tenantId,invoiceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Unsent Invoice
 */
export const deleteUnsentInvoiceV1 = (
    tenantId: string,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices/${invoiceId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteUnsentInvoiceV1MutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoiceV1>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoiceV1>>, TError,{tenantId: string;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUnsentInvoiceV1>>, {tenantId: string;invoiceId: string}> = (props) => {
          const {tenantId,invoiceId} = props ?? {};

          return  deleteUnsentInvoiceV1(tenantId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteUnsentInvoiceV1MutationResult = NonNullable<Awaited<ReturnType<typeof deleteUnsentInvoiceV1>>>
    
    export type DeleteUnsentInvoiceV1MutationError = unknown

    /**
 * @summary Delete Unsent Invoice
 */
export const useDeleteUnsentInvoiceV1 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoiceV1>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteUnsentInvoiceV1MutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Generate Init CR Table
 */
export const generateInitCRTable = (
    params: GenerateInitCRTableParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<number[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/cr_tables/init`, method: 'get',
        params
    },
      options);
    }
  

export const getGenerateInitCRTableQueryKey = (params: GenerateInitCRTableParams,) => [`/api/v1/devops/billing-proxy/api/v1/billing/cr_tables/init`, ...(params ? [params]: [])] as const;
  

    
export const getGenerateInitCRTableQueryOptions = <TData = Awaited<ReturnType<typeof generateInitCRTable>>, TError = unknown>(params: GenerateInitCRTableParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateInitCRTable>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof generateInitCRTable>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGenerateInitCRTableQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof generateInitCRTable>>> = () => generateInitCRTable(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GenerateInitCRTableQueryResult = NonNullable<Awaited<ReturnType<typeof generateInitCRTable>>>
export type GenerateInitCRTableQueryError = unknown

/**
 * @summary Generate Init CR Table
 */
export const useGenerateInitCRTable = <TData = Awaited<ReturnType<typeof generateInitCRTable>>, TError = unknown>(
 params: GenerateInitCRTableParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateInitCRTable>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGenerateInitCRTableQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Billing Configs By Managed Tenant
 */
export const getBillingConfigsByManagedTenant = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingConfig>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/billing_configs/managed_tenants/${tenantId}`, method: 'get'
    },
      options);
    }
  

export const getGetBillingConfigsByManagedTenantQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/billing_configs/managed_tenants/${tenantId}`] as const;
  

    
export const getGetBillingConfigsByManagedTenantQueryOptions = <TData = Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillingConfigsByManagedTenantQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>> = () => getBillingConfigsByManagedTenant(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetBillingConfigsByManagedTenantQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>>
export type GetBillingConfigsByManagedTenantQueryError = unknown

/**
 * @summary Get Billing Configs By Managed Tenant
 */
export const useGetBillingConfigsByManagedTenant = <TData = Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingConfigsByManagedTenant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBillingConfigsByManagedTenantQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get BillingInfo
 */
export const getBillingInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'get'
    },
      options);
    }
  

export const getGetBillingInfoQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_info`] as const;
  

    
export const getGetBillingInfoQueryOptions = <TData = Awaited<ReturnType<typeof getBillingInfo>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillingInfoQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingInfo>>> = () => getBillingInfo(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetBillingInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingInfo>>>
export type GetBillingInfoQueryError = unknown

/**
 * @summary Get BillingInfo
 */
export const useGetBillingInfo = <TData = Awaited<ReturnType<typeof getBillingInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBillingInfoQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update BillingInfo
 */
export const updateBillingInfo = (
    tenantId: string,
    formsBillingInformationInput: FormsBillingInformationInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsBillingInformationInput
    },
      options);
    }
  


export const getUpdateBillingInfoMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingInfo>>, TError,{tenantId: string;data: FormsBillingInformationInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateBillingInfo>>, TError,{tenantId: string;data: FormsBillingInformationInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBillingInfo>>, {tenantId: string;data: FormsBillingInformationInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateBillingInfo(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateBillingInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updateBillingInfo>>>
    export type UpdateBillingInfoMutationBody = FormsBillingInformationInput
    export type UpdateBillingInfoMutationError = unknown

    /**
 * @summary Update BillingInfo
 */
export const useUpdateBillingInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingInfo>>, TError,{tenantId: string;data: FormsBillingInformationInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateBillingInfoMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Tenant TaxRate
 */
export const updateTenantTaxRate = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTaxRate>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/tax_rates`, method: 'post'
    },
      options);
    }
  


export const getUpdateTenantTaxRateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantTaxRate>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenantTaxRate>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenantTaxRate>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  updateTenantTaxRate(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantTaxRateMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenantTaxRate>>>
    
    export type UpdateTenantTaxRateMutationError = unknown

    /**
 * @summary Update Tenant TaxRate
 */
export const useUpdateTenantTaxRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantTaxRate>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateTenantTaxRateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Billing Configs
 */
export const listBillingConfigs = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingConfig[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_configs`, method: 'get'
    },
      options);
    }
  

export const getListBillingConfigsQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_configs`] as const;
  

    
export const getListBillingConfigsQueryOptions = <TData = Awaited<ReturnType<typeof listBillingConfigs>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBillingConfigs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listBillingConfigs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListBillingConfigsQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listBillingConfigs>>> = () => listBillingConfigs(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type ListBillingConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof listBillingConfigs>>>
export type ListBillingConfigsQueryError = unknown

/**
 * @summary List Billing Configs
 */
export const useListBillingConfigs = <TData = Awaited<ReturnType<typeof listBillingConfigs>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBillingConfigs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListBillingConfigsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Invoices
 */
export const listInvoices = (
    tenantId: string,
    billingConfigId: number,
    params?: ListInvoicesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices`, method: 'get',
        params
    },
      options);
    }
  

export const getListInvoicesQueryKey = (tenantId: string,
    billingConfigId: number,
    params?: ListInvoicesParams,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices`, ...(params ? [params]: [])] as const;
  

    
export const getListInvoicesQueryOptions = <TData = Awaited<ReturnType<typeof listInvoices>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    params?: ListInvoicesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listInvoices>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInvoicesQueryKey(tenantId,billingConfigId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInvoices>>> = () => listInvoices(tenantId,billingConfigId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listInvoices>>>
export type ListInvoicesQueryError = unknown

/**
 * @summary List Invoices
 */
export const useListInvoices = <TData = Awaited<ReturnType<typeof listInvoices>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    params?: ListInvoicesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInvoicesQueryOptions(tenantId,billingConfigId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Invoice
 */
export const createInvoice = (
    tenantId: string,
    billingConfigId: number,
    formsCreateInvoiceReqBody: FormsCreateInvoiceReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateInvoiceReqBody
    },
      options);
    }
  


export const getCreateInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateInvoiceReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateInvoiceReqBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInvoice>>, {tenantId: string;billingConfigId: number;data: FormsCreateInvoiceReqBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  createInvoice(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof createInvoice>>>
    export type CreateInvoiceMutationBody = FormsCreateInvoiceReqBody
    export type CreateInvoiceMutationError = unknown

    /**
 * @summary Create Invoice
 */
export const useCreateInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCreateInvoiceReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Public Support Plan
 */
export const getPublicSupportPlan = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSupportPlan[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/support_plan`, method: 'get'
    },
      options);
    }
  

export const getGetPublicSupportPlanQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/support_plan`] as const;
  

    
export const getGetPublicSupportPlanQueryOptions = <TData = Awaited<ReturnType<typeof getPublicSupportPlan>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getPublicSupportPlan>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicSupportPlanQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicSupportPlan>>> = () => getPublicSupportPlan(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetPublicSupportPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicSupportPlan>>>
export type GetPublicSupportPlanQueryError = unknown

/**
 * @summary Get Public Support Plan
 */
export const useGetPublicSupportPlan = <TData = Awaited<ReturnType<typeof getPublicSupportPlan>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPublicSupportPlanQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Tenant Bind Private Support Plan
 */
export const tenantBindPrivateSupportPlan = (
    tenantId: string,
    formsSupportPlanInput: FormsSupportPlanInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan/private`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsSupportPlanInput
    },
      options);
    }
  


export const getTenantBindPrivateSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantBindPrivateSupportPlan>>, TError,{tenantId: string;data: FormsSupportPlanInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof tenantBindPrivateSupportPlan>>, TError,{tenantId: string;data: FormsSupportPlanInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantBindPrivateSupportPlan>>, {tenantId: string;data: FormsSupportPlanInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantBindPrivateSupportPlan(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type TenantBindPrivateSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof tenantBindPrivateSupportPlan>>>
    export type TenantBindPrivateSupportPlanMutationBody = FormsSupportPlanInput
    export type TenantBindPrivateSupportPlanMutationError = unknown

    /**
 * @summary Tenant Bind Private Support Plan
 */
export const useTenantBindPrivateSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantBindPrivateSupportPlan>>, TError,{tenantId: string;data: FormsSupportPlanInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getTenantBindPrivateSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Public Support Plan
 */
export const updatePublicSupportPlan = (
    supportPlanId: string,
    formsSupportPlanInput: FormsSupportPlanInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSupportPlan>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/support_plan/${supportPlanId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsSupportPlanInput
    },
      options);
    }
  


export const getUpdatePublicSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePublicSupportPlan>>, TError,{supportPlanId: string;data: FormsSupportPlanInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePublicSupportPlan>>, TError,{supportPlanId: string;data: FormsSupportPlanInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePublicSupportPlan>>, {supportPlanId: string;data: FormsSupportPlanInput}> = (props) => {
          const {supportPlanId,data} = props ?? {};

          return  updatePublicSupportPlan(supportPlanId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdatePublicSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof updatePublicSupportPlan>>>
    export type UpdatePublicSupportPlanMutationBody = FormsSupportPlanInput
    export type UpdatePublicSupportPlanMutationError = unknown

    /**
 * @summary Update Public Support Plan
 */
export const useUpdatePublicSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePublicSupportPlan>>, TError,{supportPlanId: string;data: FormsSupportPlanInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdatePublicSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Tenant Bind Public Support Plan
 */
export const tenantBindPublicSupportPlan = (
    tenantId: string,
    formsBindedSupportPlan: FormsBindedSupportPlan,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan/public`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsBindedSupportPlan
    },
      options);
    }
  


export const getTenantBindPublicSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, TError,{tenantId: string;data: FormsBindedSupportPlan}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, TError,{tenantId: string;data: FormsBindedSupportPlan}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, {tenantId: string;data: FormsBindedSupportPlan}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantBindPublicSupportPlan(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type TenantBindPublicSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>>
    export type TenantBindPublicSupportPlanMutationBody = FormsBindedSupportPlan
    export type TenantBindPublicSupportPlanMutationError = unknown

    /**
 * @summary Tenant Bind Public Support Plan
 */
export const useTenantBindPublicSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, TError,{tenantId: string;data: FormsBindedSupportPlan}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getTenantBindPublicSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Tenant Public Support Plan
 */
export const cancelTennatPublicSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan/public`, method: 'delete'
    },
      options);
    }
  


export const getCancelTennatPublicSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTennatPublicSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelTennatPublicSupportPlan>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTennatPublicSupportPlan>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelTennatPublicSupportPlan(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelTennatPublicSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTennatPublicSupportPlan>>>
    
    export type CancelTennatPublicSupportPlanMutationError = unknown

    /**
 * @summary Cancel Tenant Public Support Plan
 */
export const useCancelTennatPublicSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTennatPublicSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelTennatPublicSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant Support Plan
 */
export const getTenantSupportPlanV2 = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan`, method: 'get'
    },
      options);
    }
  

export const getGetTenantSupportPlanV2QueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan`] as const;
  

    
export const getGetTenantSupportPlanV2QueryOptions = <TData = Awaited<ReturnType<typeof getTenantSupportPlanV2>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlanV2>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlanV2>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantSupportPlanV2QueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantSupportPlanV2>>> = () => getTenantSupportPlanV2(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type GetTenantSupportPlanV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTenantSupportPlanV2>>>
export type GetTenantSupportPlanV2QueryError = unknown

/**
 * @summary Get Tenant Support Plan
 */
export const useGetTenantSupportPlanV2 = <TData = Awaited<ReturnType<typeof getTenantSupportPlanV2>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlanV2>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantSupportPlanV2QueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant Support Plan
 */
export const getTenantSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan`, method: 'get'
    },
      options);
    }
  

export const getGetTenantSupportPlanQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan`] as const;
  

    
export const getGetTenantSupportPlanQueryOptions = <TData = Awaited<ReturnType<typeof getTenantSupportPlan>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantSupportPlanQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantSupportPlan>>> = () => getTenantSupportPlan(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantSupportPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantSupportPlan>>>
export type GetTenantSupportPlanQueryError = unknown

/**
 * @summary Get Tenant Support Plan
 */
export const useGetTenantSupportPlan = <TData = Awaited<ReturnType<typeof getTenantSupportPlan>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantSupportPlanQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cancel Activated Tenant Support Plan
 */
export const cancelActivatedTenantSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan`, method: 'delete'
    },
      options);
    }
  


export const getCancelActivatedTenantSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelActivatedTenantSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelActivatedTenantSupportPlan>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelActivatedTenantSupportPlan>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelActivatedTenantSupportPlan(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelActivatedTenantSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof cancelActivatedTenantSupportPlan>>>
    
    export type CancelActivatedTenantSupportPlanMutationError = unknown

    /**
 * @summary Cancel Activated Tenant Support Plan
 */
export const useCancelActivatedTenantSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelActivatedTenantSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelActivatedTenantSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Tenant Support Plan
 */
export const cancelTenantSupportPlan = (
    tenantId: string,
    bindedSupportPlanId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/binded_support_plan/${bindedSupportPlanId}`, method: 'delete'
    },
      options);
    }
  


export const getCancelTenantSupportPlanMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, TError,{tenantId: string;bindedSupportPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, TError,{tenantId: string;bindedSupportPlanId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, {tenantId: string;bindedSupportPlanId: string}> = (props) => {
          const {tenantId,bindedSupportPlanId} = props ?? {};

          return  cancelTenantSupportPlan(tenantId,bindedSupportPlanId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelTenantSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTenantSupportPlan>>>
    
    export type CancelTenantSupportPlanMutationError = unknown

    /**
 * @summary Cancel Tenant Support Plan
 */
export const useCancelTenantSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, TError,{tenantId: string;bindedSupportPlanId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelTenantSupportPlanMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Tenant Support Plans
 */
export const listTenantSupportPlans = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan/list`, method: 'get'
    },
      options);
    }
  

export const getListTenantSupportPlansQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan/list`] as const;
  

    
export const getListTenantSupportPlansQueryOptions = <TData = Awaited<ReturnType<typeof listTenantSupportPlans>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantSupportPlans>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantSupportPlans>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantSupportPlansQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantSupportPlans>>> = () => listTenantSupportPlans(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListTenantSupportPlansQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantSupportPlans>>>
export type ListTenantSupportPlansQueryError = unknown

/**
 * @summary List Tenant Support Plans
 */
export const useListTenantSupportPlans = <TData = Awaited<ReturnType<typeof listTenantSupportPlans>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantSupportPlans>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantSupportPlansQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Commitment Invoice
 */
export const createCommitmentInvoice = (
    tenantId: string,
    billingConfigId: number,
    formsCommitmentInvoiceInput: FormsCommitmentInvoiceInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/commitment_invoices`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCommitmentInvoiceInput
    },
      options);
    }
  


export const getCreateCommitmentInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCommitmentInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCommitmentInvoiceInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCommitmentInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCommitmentInvoiceInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCommitmentInvoice>>, {tenantId: string;billingConfigId: number;data: FormsCommitmentInvoiceInput}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  createCommitmentInvoice(tenantId,billingConfigId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCommitmentInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof createCommitmentInvoice>>>
    export type CreateCommitmentInvoiceMutationBody = FormsCommitmentInvoiceInput
    export type CreateCommitmentInvoiceMutationError = unknown

    /**
 * @summary Create Commitment Invoice
 */
export const useCreateCommitmentInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCommitmentInvoice>>, TError,{tenantId: string;billingConfigId: number;data: FormsCommitmentInvoiceInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCommitmentInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Invoice
 */
export const getInvoice = (
    tenantId: string,
    billingConfigId: number,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}`, method: 'get'
    },
      options);
    }
  

export const getGetInvoiceQueryKey = (tenantId: string,
    billingConfigId: number,
    invoiceId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}`] as const;
  

    
export const getGetInvoiceQueryOptions = <TData = Awaited<ReturnType<typeof getInvoice>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInvoiceQueryKey(tenantId,billingConfigId,invoiceId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoice>>> = () => getInvoice(tenantId,billingConfigId,invoiceId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && invoiceId), ...queryOptions}}

export type GetInvoiceQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoice>>>
export type GetInvoiceQueryError = unknown

/**
 * @summary Get Invoice
 */
export const useGetInvoice = <TData = Awaited<ReturnType<typeof getInvoice>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    invoiceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInvoice>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInvoiceQueryOptions(tenantId,billingConfigId,invoiceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Unsent Invoice
 */
export const deleteUnsentInvoice = (
    tenantId: string,
    billingConfigId: number,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteUnsentInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUnsentInvoice>>, {tenantId: string;billingConfigId: number;invoiceId: string}> = (props) => {
          const {tenantId,billingConfigId,invoiceId} = props ?? {};

          return  deleteUnsentInvoice(tenantId,billingConfigId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteUnsentInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUnsentInvoice>>>
    
    export type DeleteUnsentInvoiceMutationError = unknown

    /**
 * @summary Delete Unsent Invoice
 */
export const useDeleteUnsentInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUnsentInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteUnsentInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Confirm Invoice
 */
export const confirmInvoice = (
    tenantId: string,
    billingConfigId: number,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}/confirm`, method: 'put'
    },
      options);
    }
  


export const getConfirmInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmInvoice>>, {tenantId: string;billingConfigId: number;invoiceId: string}> = (props) => {
          const {tenantId,billingConfigId,invoiceId} = props ?? {};

          return  confirmInvoice(tenantId,billingConfigId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ConfirmInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof confirmInvoice>>>
    
    export type ConfirmInvoiceMutationError = unknown

    /**
 * @summary Confirm Invoice
 */
export const useConfirmInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getConfirmInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const modifyInvoice = (
    tenantId: string,
    billingConfigId: number,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}/modify`, method: 'post'
    },
      options);
    }
  


export const getModifyInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof modifyInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof modifyInvoice>>, {tenantId: string;billingConfigId: number;invoiceId: string}> = (props) => {
          const {tenantId,billingConfigId,invoiceId} = props ?? {};

          return  modifyInvoice(tenantId,billingConfigId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ModifyInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof modifyInvoice>>>
    
    export type ModifyInvoiceMutationError = unknown

    export const useModifyInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyInvoice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getModifyInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send Invoice
 */
export const sendInovice = (
    tenantId: string,
    billingConfigId: number,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/invoices/${invoiceId}/send`, method: 'put'
    },
      options);
    }
  


export const getSendInoviceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendInovice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof sendInovice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendInovice>>, {tenantId: string;billingConfigId: number;invoiceId: string}> = (props) => {
          const {tenantId,billingConfigId,invoiceId} = props ?? {};

          return  sendInovice(tenantId,billingConfigId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SendInoviceMutationResult = NonNullable<Awaited<ReturnType<typeof sendInovice>>>
    
    export type SendInoviceMutationError = unknown

    /**
 * @summary Send Invoice
 */
export const useSendInovice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendInovice>>, TError,{tenantId: string;billingConfigId: number;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSendInoviceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * ListStripeInvoices
 * @summary ListStripeInvoices
 */
export const listStripeInvoices = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsStripeInvoice[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/invoices`, method: 'get'
    },
      options);
    }
  

export const getListStripeInvoicesQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/invoices`] as const;
  

    
export const getListStripeInvoicesQueryOptions = <TData = Awaited<ReturnType<typeof listStripeInvoices>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStripeInvoicesQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStripeInvoices>>> = () => listStripeInvoices(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListStripeInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listStripeInvoices>>>
export type ListStripeInvoicesQueryError = unknown

/**
 * @summary ListStripeInvoices
 */
export const useListStripeInvoices = <TData = Awaited<ReturnType<typeof listStripeInvoices>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStripeInvoicesQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Tenant Payment Methods
 */
export const listTenantPaymentMethods = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsPaymentMethod[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments`, method: 'get'
    },
      options);
    }
  

export const getListTenantPaymentMethodsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments`] as const;
  

    
export const getListTenantPaymentMethodsQueryOptions = <TData = Awaited<ReturnType<typeof listTenantPaymentMethods>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentMethods>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantPaymentMethodsQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantPaymentMethods>>> = () => listTenantPaymentMethods(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListTenantPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantPaymentMethods>>>
export type ListTenantPaymentMethodsQueryError = unknown

/**
 * @summary List Tenant Payment Methods
 */
export const useListTenantPaymentMethods = <TData = Awaited<ReturnType<typeof listTenantPaymentMethods>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantPaymentMethodsQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Active PaymentMethod
 */
export const getActivePaymentMethod = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsPaymentMethod>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/active`, method: 'get'
    },
      options);
    }
  

export const getGetActivePaymentMethodQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/active`] as const;
  

    
export const getGetActivePaymentMethodQueryOptions = <TData = Awaited<ReturnType<typeof getActivePaymentMethod>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivePaymentMethod>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getActivePaymentMethod>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActivePaymentMethodQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivePaymentMethod>>> = () => getActivePaymentMethod(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type GetActivePaymentMethodQueryResult = NonNullable<Awaited<ReturnType<typeof getActivePaymentMethod>>>
export type GetActivePaymentMethodQueryError = unknown

/**
 * @summary Get Active PaymentMethod
 */
export const useGetActivePaymentMethod = <TData = Awaited<ReturnType<typeof getActivePaymentMethod>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivePaymentMethod>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActivePaymentMethodQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Tenant Payment Types
 */
export const listTenantPaymentTypes = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsPaymentMethodType[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/types`, method: 'get'
    },
      options);
    }
  

export const getListTenantPaymentTypesQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/types`] as const;
  

    
export const getListTenantPaymentTypesQueryOptions = <TData = Awaited<ReturnType<typeof listTenantPaymentTypes>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentTypes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantPaymentTypesQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantPaymentTypes>>> = () => listTenantPaymentTypes(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListTenantPaymentTypesQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantPaymentTypes>>>
export type ListTenantPaymentTypesQueryError = unknown

/**
 * @summary List Tenant Payment Types
 */
export const useListTenantPaymentTypes = <TData = Awaited<ReturnType<typeof listTenantPaymentTypes>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantPaymentTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantPaymentTypesQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Setup PaymentMethod
 */
export const setupPaymentMethodV1 = (
    tenantId: string,
    paymentType: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/payments/setup/${paymentType}`, method: 'post'
    },
      options);
    }
  


export const getSetupPaymentMethodV1MutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethodV1>>, TError,{tenantId: string;paymentType: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethodV1>>, TError,{tenantId: string;paymentType: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setupPaymentMethodV1>>, {tenantId: string;paymentType: string}> = (props) => {
          const {tenantId,paymentType} = props ?? {};

          return  setupPaymentMethodV1(tenantId,paymentType,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetupPaymentMethodV1MutationResult = NonNullable<Awaited<ReturnType<typeof setupPaymentMethodV1>>>
    
    export type SetupPaymentMethodV1MutationError = unknown

    /**
 * @summary Setup PaymentMethod
 */
export const useSetupPaymentMethodV1 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethodV1>>, TError,{tenantId: string;paymentType: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSetupPaymentMethodV1MutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Setup PaymentMethod
 */
export const setupPaymentMethod = (
    tenantId: string,
    billingConfigId: number,
    paymentType: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/setup/${paymentType}`, method: 'post'
    },
      options);
    }
  


export const getSetupPaymentMethodMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethod>>, TError,{tenantId: string;billingConfigId: number;paymentType: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethod>>, TError,{tenantId: string;billingConfigId: number;paymentType: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setupPaymentMethod>>, {tenantId: string;billingConfigId: number;paymentType: string}> = (props) => {
          const {tenantId,billingConfigId,paymentType} = props ?? {};

          return  setupPaymentMethod(tenantId,billingConfigId,paymentType,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetupPaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof setupPaymentMethod>>>
    
    export type SetupPaymentMethodMutationError = unknown

    /**
 * @summary Setup PaymentMethod
 */
export const useSetupPaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupPaymentMethod>>, TError,{tenantId: string;billingConfigId: number;paymentType: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSetupPaymentMethodMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getBillsDetails = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExplorerDetailsResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/bills_details/year/${year}/month/${month}`, method: 'get'
    },
      options);
    }
  

export const getGetBillsDetailsQueryKey = (tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/bills_details/year/${year}/month/${month}`] as const;
  

    
export const getGetBillsDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillsDetailsQueryKey(tenantId,billingConfigId,year,month);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillsDetails>>> = () => getBillsDetails(tenantId,billingConfigId,year,month, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && year && month), ...queryOptions}}

export type GetBillsDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getBillsDetails>>>
export type GetBillsDetailsQueryError = unknown

export const useGetBillsDetails = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBillsDetailsQueryOptions(tenantId,billingConfigId,year,month,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const getMonthlyBillsExport = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_bills/year/${year}/month/${month}/export`, method: 'post'
    },
      options);
    }
  


export const getGetMonthlyBillsExportMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getMonthlyBillsExport>>, {tenantId: string;billingConfigId: number;year: string;month: string}> = (props) => {
          const {tenantId,billingConfigId,year,month} = props ?? {};

          return  getMonthlyBillsExport(tenantId,billingConfigId,year,month,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GetMonthlyBillsExportMutationResult = NonNullable<Awaited<ReturnType<typeof getMonthlyBillsExport>>>
    
    export type GetMonthlyBillsExportMutationError = unknown

    export const useGetMonthlyBillsExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGetMonthlyBillsExportMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Explorer Monthly
 */
export const getMonthlyCost = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}`, method: 'get'
    },
      options);
    }
  

export const getGetMonthlyCostQueryKey = (tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}`] as const;
  

    
export const getGetMonthlyCostQueryOptions = <TData = Awaited<ReturnType<typeof getMonthlyCost>>, TError = unknown>(tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMonthlyCostQueryKey(tenantId,billingConfigId,year,month);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonthlyCost>>> = () => getMonthlyCost(tenantId,billingConfigId,year,month, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId && year && month), ...queryOptions}}

export type GetMonthlyCostQueryResult = NonNullable<Awaited<ReturnType<typeof getMonthlyCost>>>
export type GetMonthlyCostQueryError = unknown

/**
 * @summary Cost Explorer Monthly
 */
export const useGetMonthlyCost = <TData = Awaited<ReturnType<typeof getMonthlyCost>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMonthlyCostQueryOptions(tenantId,billingConfigId,year,month,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cost Explorer Monthly
 */
export const exportMonthBillCSV = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}/export`, method: 'post'
    },
      options);
    }
  


export const getExportMonthBillCSVMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportMonthBillCSV>>, {tenantId: string;billingConfigId: number;year: string;month: string}> = (props) => {
          const {tenantId,billingConfigId,year,month} = props ?? {};

          return  exportMonthBillCSV(tenantId,billingConfigId,year,month,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ExportMonthBillCSVMutationResult = NonNullable<Awaited<ReturnType<typeof exportMonthBillCSV>>>
    
    export type ExportMonthBillCSVMutationError = unknown

    /**
 * @summary Cost Explorer Monthly
 */
export const useExportMonthBillCSV = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getExportMonthBillCSVMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * ListStripePaymentMethods
 * @summary ListPaymentMethods
 */
export const listStripePaymentMethods = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsStripePaymentMethod[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods`, method: 'get'
    },
      options);
    }
  

export const getListStripePaymentMethodsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods`] as const;
  

    
export const getListStripePaymentMethodsQueryOptions = <TData = Awaited<ReturnType<typeof listStripePaymentMethods>>, TError = unknown>(tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripePaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listStripePaymentMethods>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStripePaymentMethodsQueryKey(tenantId,billingConfigId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStripePaymentMethods>>> = () => listStripePaymentMethods(tenantId,billingConfigId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}}

export type ListStripePaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listStripePaymentMethods>>>
export type ListStripePaymentMethodsQueryError = unknown

/**
 * @summary ListPaymentMethods
 */
export const useListStripePaymentMethods = <TData = Awaited<ReturnType<typeof listStripePaymentMethods>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripePaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStripePaymentMethodsQueryOptions(tenantId,billingConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * UpdatePaymentMethod
 * @summary UpdatePaymentMethod
 */
export const putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
    formsStripePaymentMethod: FormsStripePaymentMethod,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsStripePaymentMethod>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsStripePaymentMethod
    },
      options);
    }
  


export const getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, {tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId,data} = props ?? {};

          return  putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId(tenantId,billingConfigId,paymentMethodId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>>
    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationBody = FormsStripePaymentMethod
    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationError = unknown

    /**
 * @summary UpdatePaymentMethod
 */
export const usePutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * DeletePaymentMethod
 * @summary DeletePaymentMethod
 */
export const deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, {tenantId: string;billingConfigId: number;paymentMethodId: string}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId} = props ?? {};

          return  deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId(tenantId,billingConfigId,paymentMethodId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>>
    
    export type DeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationError = unknown

    /**
 * @summary DeletePaymentMethod
 */
export const useDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodId>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * SetPaymentMethodDefault
 * @summary SetPaymentMethodDefault
 */
export const putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}/default`, method: 'put'
    },
      options);
    }
  


export const getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefaultMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault>>, {tenantId: string;billingConfigId: number;paymentMethodId: string}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId} = props ?? {};

          return  putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault(tenantId,billingConfigId,paymentMethodId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault>>>
    
    export type PutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefaultMutationError = unknown

    /**
 * @summary SetPaymentMethodDefault
 */
export const usePutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefault>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdBillingConfigIdBillingConfigIdPaymentsStripePaymentMethodsPaymentMethodIdDefaultMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Units Measure
 */
export const costUnitsMeasure = (
    tenantId: string,
    formsUnitCostsInputBody: FormsUnitCostsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitCosts>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/measures`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitCostsInputBody
    },
      options);
    }
  


export const getCostUnitsMeasureMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costUnitsMeasure>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof costUnitsMeasure>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof costUnitsMeasure>>, {tenantId: string;data: FormsUnitCostsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  costUnitsMeasure(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CostUnitsMeasureMutationResult = NonNullable<Awaited<ReturnType<typeof costUnitsMeasure>>>
    export type CostUnitsMeasureMutationBody = FormsUnitCostsInputBody
    export type CostUnitsMeasureMutationError = unknown

    /**
 * @summary Cost Units Measure
 */
export const useCostUnitsMeasure = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costUnitsMeasure>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCostUnitsMeasureMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Units Dryrun
 */
export const costUnitsDryrun = (
    tenantId: string,
    formsUnitCostsInputBody: FormsUnitCostsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitCosts>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/measures/dryrun`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitCostsInputBody
    },
      options);
    }
  


export const getCostUnitsDryrunMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costUnitsDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof costUnitsDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof costUnitsDryrun>>, {tenantId: string;data: FormsUnitCostsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  costUnitsDryrun(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CostUnitsDryrunMutationResult = NonNullable<Awaited<ReturnType<typeof costUnitsDryrun>>>
    export type CostUnitsDryrunMutationBody = FormsUnitCostsInputBody
    export type CostUnitsDryrunMutationError = unknown

    /**
 * @summary Cost Units Dryrun
 */
export const useCostUnitsDryrun = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costUnitsDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCostUnitsDryrunMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
