import { Modal, Table } from 'antd'
import React, { useMemo } from 'react'

import { FormsCategoryCosts } from 'apiClient/api/interface'
import { useGetInvoice } from 'apiClient/services/billing'
import { FormsInvoiceForOpsList } from 'apiClient/services/billing/interface'
import CurrencyValue from 'components/currency/Value'

import BillCosts from './BillCosts'

export default function InvoiceDetailsModalForm({
  open,
  invoice,
  orgId,
  billingConfigId,
  onCancel
}: {
  open: boolean
  orgId: string
  billingConfigId: number
  invoice: FormsInvoiceForOpsList
  onCancel: () => void
}) {
  const { data, isLoading } = useGetInvoice(
    orgId,
    billingConfigId,
    invoice.invoice_id!.toString(),
    {
      query: {
        enabled: open
      }
    }
  )

  const columns = useMemo(
    () => [
      {
        title: 'Project Name',
        dataIndex: 'name'
      },
      {
        title: 'Cost Price',
        dataIndex: 'cost_price',
        render: (text: any, record: FormsCategoryCosts) => {
          return <CurrencyValue isCent={false} value={record.cost_price} />
        }
      }
    ],
    []
  )

  const dataSource = data?.data?.label_unit_costs?.project
    ? Object.keys(data.data.label_unit_costs.project).map((key) => ({
        name: key,
        // @ts-ignore
        ...data.data.label_unit_costs.project[key]
      }))
    : []

  return (
    <Modal
      visible={open}
      title="Bill Details"
      width={1000}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="name"
        size="small"
        expandable={{
          expandedRowRender: (record) => (
            <BillCosts category_unit_costs={record.category_unit_costs || {}} />
          ),
          defaultExpandAllRows: true
        }}
      />
    </Modal>
  )
}
